import { useIntl } from "gatsby-plugin-react-intl";
import React from "react";
import Breadcrumbs from '../components/Breadcrumbs';
import ContactForm from '../components/Form/ContactForm';
import { ContentFlow, ScreenSize } from '../landingnewcomponents/Flow';
import Paragraph from "../landingnewcomponents/Paragraph/Landing";
import Section from '../landingnewcomponents/Section/Landing';
import SubTitle from '../landingnewcomponents/SubTitle/Landing';
import Title, { TITLE_COLORS } from '../landingnewcomponents/Title/Landing';
import Layout from "../layouts/layout";
import ContactUsSection from '../sections/contact-us';
import "./../common/styles.scss";


const ContactsPage = ({ location }) => {
    const { formatMessage, locale } = useIntl();


    return (
        <Layout location={location}>
            <Section>
                <Breadcrumbs
                    items={[{ title: "Viewst", url: "/" }, { title: "Contacts", url: "/contacts/" }]}
                />
                <Title color={TITLE_COLORS.DarkGray}>Contacts</Title>
                <ContentFlow horisontal={[ScreenSize.L, ScreenSize.M]} vertical={[ScreenSize.S]}>
                    <div style={{ "width": "50%", "margin-right": "60px" }}>
                        <SubTitle with_bg color={TITLE_COLORS.White}>Address</SubTitle>
                        <Paragraph>541 Jefferson Ave,</Paragraph>
                        <Paragraph>Redwood City, CA 94063,</Paragraph>
                        <Paragraph> United States</Paragraph>
                        <Paragraph>View map direction</Paragraph>
                    </div>
                    <div>
                        <SubTitle with_bg color={TITLE_COLORS.White}>Phone</SubTitle>
                        <Paragraph>+1 415 481 5682</Paragraph>
                        <Paragraph>Mo–Fr, 10:00–18:00</Paragraph>
                    </div>
                </ContentFlow>
                <SubTitle with_bg color={TITLE_COLORS.White}>Contact form</SubTitle>
                <ContactForm action="#" />
            </Section>
            <ContactUsSection />
        </Layout >
    );
};

export default ContactsPage;
