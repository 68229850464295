import { cn } from "@bem-react/classname";
import { useIntl } from "gatsby-plugin-react-intl";
import React from 'react';
import { Horisontal } from '../../landingnewcomponents/Flow';
import { PromoLink } from '../../landingnewcomponents/Link/Landing';
import Input from './Input';
import Label from './Label';
import Textarea from './Textarea';

const ContactForm = ({ action }) => {
    const classItem = cn('ContactForm');

    const intl = useIntl();
    return (
        <form action={action}>
            <div className="Form-Row">
                <Horisontal spaced>
                    <Label>Email*</Label>
                    <Input type="email" required />
                </Horisontal>
            </div>
            <div className="Form-Row">
                <Horisontal spaced>
                    <Label>First name*</Label>
                    <Input type="text" required />
                </Horisontal>
            </div>
            <div className="Form-Row">
                <Horisontal spaced>
                    <Label>Last name*</Label>
                    <Input type="text" required />
                </Horisontal></div>
            <div className="Form-Row">
                <Horisontal spaced>
                    <Label>Phone number</Label>
                    <Input type="phone" />
                </Horisontal></div>
            <div className="Form-Row">
                <Horisontal spaced>
                    <Label>Company name</Label>
                    <Input type="text" />
                </Horisontal></div>
            <div className="Form-Row">
                <Horisontal spaced>
                    <Label>Comment</Label>
                    <Textarea />
                </Horisontal>
            </div>

            <PromoLink buttonLike>Submit</PromoLink>
        </form>
    )
};

export default ContactForm;
