import { cn } from "@bem-react/classname";
import { useIntl } from "gatsby-plugin-react-intl";
import React from 'react';
import "./Form.scss";

const Input = ({ type, required, children }) => {
    const classItem = cn('Input');

    const intl = useIntl();
    return (
        <input className={classItem()} type={type} required>{children}</input>
    )
};

export default Input;
