import { cn } from "@bem-react/classname";
import { useIntl } from "gatsby-plugin-react-intl";
import React from 'react';
import "./Form.scss";

const Label = ({ children }) => {
    const classItem = cn('Label');

    const intl = useIntl();
    return (
        <label className={classItem()}>{children}</label>
    )
};

export default Label;
