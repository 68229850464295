import { cn } from "@bem-react/classname";
import { useIntl } from "gatsby-plugin-react-intl";
import React from 'react';
import "./Form.scss";

const Textarea = ({ children }) => {
    const classItem = cn('Textarea');

    const intl = useIntl();
    return (
        <textarea className={classItem()}>{children}</textarea>
    )
};

export default Textarea;
